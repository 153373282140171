// src/modules/Billing/Billing.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PaymentMethodManager from './PaymentMethodManager';
import CreditsAndUsage from './CreditsAndUsage';
import PlanManager from './PlanManager';
import CheckoutFlow from './CheckoutFlow';
import API_BASE_URL from '../../constants/apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Billing.css';
import LoadingSpinner from '../shared/LoadingSpinner';
const Billing = () => {
  const user = useSelector((state) => state.user.user);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkPaymentMethod = async () => {
      if (user && user.customer_id) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/payment-method?customer_id=${user.customer_id}`);
          if (response.ok) {
            const data = await response.json();
            setHasPaymentMethod(!!data);
          } else {
            setHasPaymentMethod(false);
          }
        } catch (error) {
          console.error('Error checking payment method:', error);
          setHasPaymentMethod(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    checkPaymentMethod();
  }, [user]);

  const handlePaymentMethodAdded = () => {
    setHasPaymentMethod(true);
  };

  if (isLoading) {
    return <div className="billing-module"><LoadingSpinner /></div>;
  }

  if (!user || !user.customer_id) {
    return <div className="billing-module">User information not available. Please log in.</div>;
  }

  if (!hasPaymentMethod) {
    return (
      <div className="billing-module">
        <div className="container mt-5 billing-container">
          <div className="card shadow">
            <div className="card-body">
              <h2>Set Up Your Billing</h2>
              <p>Please select a plan and add a payment method to get started.</p>
              <CheckoutFlow 
                onComplete={handlePaymentMethodAdded} 
                customerId={user.customer_id}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="billing-module">
      <div className="container mt-5 billing-container">
        <div className="card shadow">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2>Billing</h2>
            </div>
            
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <PaymentMethodManager onPaymentMethodAdded={handlePaymentMethodAdded} />
                  </div>
                </div>
              </div>
              
              <div className="col-md-6 mb-4">
                <div className="card h-100">
                  <div className="card-body">
                    <PlanManager customerId={user.customer_id} />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <CreditsAndUsage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;