import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'bootstrap';
import { formatPhoneNumberForDisplay } from '../shared/FormatPhoneNumber';
import './EditableJobView.css';

const EditableJobView = ({ editedJobData, setEditedJobData, setIsFormValid, onSave, onCancel }) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
  }, []);

  useEffect(() => {
    // Format the phone number when it changes
    if (editedJobData.superintendentPhoneNumber) {
      const formattedNumber = formatPhoneNumberForDisplay(editedJobData.superintendentPhoneNumber);
      if (formattedNumber !== editedJobData.superintendentPhoneNumber) {
        setEditedJobData(prevData => ({
          ...prevData,
          superintendentPhoneNumber: formattedNumber
        }));
      }
    }
  }, [editedJobData.superintendentPhoneNumber, setEditedJobData]);

  const handleEmailChange = (index, value) => {
    const updatedEmails = editedJobData.emails.map((email, i) => i === index ? value : email);
    setEditedJobData({ ...editedJobData, emails: updatedEmails });
  };

  const handleAddEmail = () => {
    setEditedJobData({ ...editedJobData, emails: [...editedJobData.emails, ''] });
  };

  const handleRemoveEmail = (index) => {
    const filteredEmails = editedJobData.emails.filter((_, i) => i !== index);
    setEditedJobData({ ...editedJobData, emails: filteredEmails });
  };

  const handleSuperintendentApprovalChange = (e) => {
    const isChecked = e.target.checked;
    setEditedJobData({
      ...editedJobData,
      requireSuperintendentApproval: isChecked,
      superintendentName: isChecked ? editedJobData.superintendentName : '',
      superintendentPhoneNumber: isChecked ? editedJobData.superintendentPhoneNumber : ''
    });
    setValidationErrors({});
    validateForm();
  };

  const validateForm = useCallback(() => {
    const errors = {};
  
    if (editedJobData.requireSuperintendentApproval) {
      if (!editedJobData.superintendentName?.trim()) {
        errors.superintendentName = 'Superintendent Name is required';
      }
      if (!editedJobData.superintendentPhoneNumber?.trim()) {
        errors.superintendentPhoneNumber = 'Superintendent Phone is required';
      } else {
        const phoneDigits = editedJobData.superintendentPhoneNumber.replace(/\D/g, '');
        if (phoneDigits.length !== 10) {
          errors.superintendentPhoneNumber = 'Phone number must have 10 digits';
        }
      }
    }
  
    setValidationErrors(errors);
    const formIsValid = Object.keys(errors).length === 0;
    setIsFormValid(formIsValid);
    return formIsValid;
  }, [editedJobData, setIsFormValid]);

  useEffect(() => {
    validateForm();
  }, [validateForm, editedJobData.requireSuperintendentApproval, editedJobData.superintendentName, editedJobData.superintendentPhoneNumber]);

  return (
    <div className="editable-job-view">
      <div className="job-values-container">
        <div className="header-section">
          <h2 className="header-title">Edit Job Details</h2>
          <div className="button-group">
            <button 
              type="button" 
              className="btn btn-danger me-2" 
              onClick={onCancel}
            >
              Cancel
            </button>
            <button 
              type="button" 
              className="btn btn-primary" 
              onClick={onSave}
            >
              Save Changes
            </button>
          </div>
        </div>

        <div className="info-section">
          <h6 className="section-title">Job Information</h6>
          <div className="input-row">
            <div className="input-group">
              <label htmlFor="jobName" className="job-values-label">Job Name</label>
              <input
                type="text"
                className={`form-control ${validationErrors.jobName ? 'is-invalid' : ''}`}
                id="jobName"
                value={editedJobData.jobName}
                onChange={(e) => setEditedJobData({ ...editedJobData, jobName: e.target.value })}
              />
              {validationErrors.jobName && (
                <div className="invalid-feedback">{validationErrors.jobName}</div>
              )}
            </div>

            <div className="input-group">
              <label htmlFor="generalContractor" className="job-values-label">General Contractor</label>
              <input
                type="text"
                className="form-control"
                id="generalContractor"
                value={editedJobData.generalContractor}
                onChange={(e) => setEditedJobData({ ...editedJobData, generalContractor: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <h6 className="section-title">Superintendent Information</h6>
          <div className="input-row">
            <div className="input-group">
              <div className="d-flex align-items-center mb-3">
                <label htmlFor="requireSuperintendentApproval" className="job-values-label mb-0">
                  Require Superintendent Approval
                  <span 
                    className="tooltip-icon ms-2"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Checking this box will allow the superintendent to sign off on all change order requests for this job."
                  >?</span>
                </label>
                <input
                  type="checkbox"
                  className="form-check-input ms-2"
                  id="requireSuperintendentApproval"
                  checked={editedJobData.requireSuperintendentApproval}
                  onChange={handleSuperintendentApprovalChange}
                />
              </div>
            </div>
          </div>

          <div className="input-row">
            <div className="input-group">
              <label htmlFor="superintendentName" className="job-values-label">Superintendent Name</label>
              <input
                type="text"
                className={`form-control ${validationErrors.superintendentName ? 'is-invalid' : ''}`}
                id="superintendentName"
                value={editedJobData.superintendentName || ''}
                onChange={(e) => setEditedJobData({ ...editedJobData, superintendentName: e.target.value })}
                disabled={!editedJobData.requireSuperintendentApproval}
              />
              {validationErrors.superintendentName && (
                <div className="invalid-feedback">{validationErrors.superintendentName}</div>
              )}
            </div>

            <div className="input-group">
              <label htmlFor="superintendentPhoneNumber" className="job-values-label">Superintendent Phone</label>
              <input
                type="tel"
                className={`form-control ${validationErrors.superintendentPhoneNumber ? 'is-invalid' : ''}`}
                id="superintendentPhoneNumber"
                value={editedJobData.superintendentPhoneNumber || ''}
                onChange={(e) => {
                  const formattedNumber = formatPhoneNumberForDisplay(e.target.value);
                  setEditedJobData({ ...editedJobData, superintendentPhoneNumber: formattedNumber });
                }}
                disabled={!editedJobData.requireSuperintendentApproval}
                placeholder="(123) 456-7890"
              />
              {validationErrors.superintendentPhoneNumber && (
                <div className="invalid-feedback">{validationErrors.superintendentPhoneNumber}</div>
              )}
            </div>
          </div>
        </div>

        <div className="info-section">
          <h6 className="section-title">COR Report Recipients</h6>
          {editedJobData.emails.map((email, index) => (
            <div key={index} className="email-input-group">
              <div className="email-input-container">
                <input
                  type="email"
                  className={`form-control ${!emailRegex.test(email) && email.length > 0 && email.includes('@') ? 'is-invalid' : ''}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                />
                {!emailRegex.test(email) && email.length > 0 && email.includes('@') && (
                  <div className="invalid-feedback">Invalid email address</div>
                )}
              </div>
              <button type="button" className="btn btn-danger" onClick={() => handleRemoveEmail(index)}>
                Remove
              </button>
            </div>
          ))}
          <div className="btn-group">
            <button type="button" className="btn btn-secondary" onClick={handleAddEmail}>
              Add Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableJobView;