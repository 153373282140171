import React from 'react';
import { useSelector } from 'react-redux';
import './Categories.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Categories = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const { categories, tax_rate } = changeOrderDetails.bigDict;

  if (!categories) return null;

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderCategorySection = (categoryData, categoryName, taxRate) => {
    if (!categoryData || typeof categoryData !== 'object' || Object.keys(categoryData).length === 0) {
      return null;
    }
  
    const subtotal = Object.entries(categoryData).reduce((total, [_, itemDetails]) => {
      return total + (itemDetails.quantity * itemDetails.cost_per_unit);
    }, 0);
  
    return (
      <div className={`category-section mb-4 ${categoryName.toLowerCase()}`} key={categoryName}>
        <h3>{capitalizeFirstLetter(categoryName)}</h3>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Item</th>
                <th>$/Unit</th>
                <th>Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(categoryData).map(([itemId, itemDetails]) => (
                <tr key={itemId}>
                  <td>{itemDetails.name}</td>
                  <td>{`${formatCurrency(itemDetails.cost_per_unit)} / ${itemDetails.unit || 'ea'}`}</td>
                  <td>{itemDetails.quantity}</td>
                  <td>{formatCurrency(itemDetails.quantity * itemDetails.cost_per_unit)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="subtotal-row table-active">
                <td colSpan="2"></td>
                <td className="text-right"><strong>Subtotal</strong></td>
                <td><strong>{formatCurrency(subtotal)}</strong></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

  return (
    <section className="categories">
      {Object.entries(categories).map(([categoryName, categoryData]) => 
        renderCategorySection(categoryData, categoryName, tax_rate)
      )}
    </section>
  );
};

export default Categories;