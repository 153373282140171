// src/modules/Jobs/NewJobPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Tooltip } from 'bootstrap';
import LoadingSpinner from '../shared/LoadingSpinner';
import API_BASE_URL from '../../constants/apiConfig';
import { formatPhoneNumberForDisplay, formatPhoneNumberForE164 } from '../shared/FormatPhoneNumber';
import './NewJobPage.css';

const NewJobPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [jobData, setJobData] = useState({
    id: '',
    jobName: '',
    generalContractor: '',
    emails: [],
    requireSuperintendentApproval: false,
    superintendentName: '',
    superintendentPhoneNumber: '',
  });

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
  }, [user, navigate]);

  useEffect(() => {
    if (jobData.superintendentPhoneNumber) {
      const formattedNumber = formatPhoneNumberForDisplay(jobData.superintendentPhoneNumber);
      if (formattedNumber !== jobData.superintendentPhoneNumber) {
        setJobData(prevData => ({
          ...prevData,
          superintendentPhoneNumber: formattedNumber
        }));
      }
    }
  }, [jobData.superintendentPhoneNumber]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = useCallback(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {};
  
    if (jobData.requireSuperintendentApproval) {
      if (!jobData.superintendentName.trim()) {
        errors.superintendentName = 'Superintendent Name is required';
      }
      if (!jobData.superintendentPhoneNumber.trim()) {
        errors.superintendentPhoneNumber = 'Superintendent Phone is required';
      } else {
        const phoneDigits = jobData.superintendentPhoneNumber.replace(/\D/g, '');
        if (phoneDigits.length !== 10) {
          errors.superintendentPhoneNumber = 'Phone number must have 10 digits';
        }
      }
    }
  
    if (!jobData.jobName.trim()) {
      errors.jobName = 'Job Name is required';
    }
  
    jobData.emails.forEach((email, index) => {
      if (!emailRegex.test(email)) {
        errors[`email${index}`] = 'Invalid email address';
      }
    });
  
    setValidationErrors(errors);
    const formIsValid = Object.keys(errors).length === 0;
    setIsFormValid(formIsValid);
    return formIsValid;
  }, [jobData]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleCancel = () => {
    navigate('/jobs');
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = jobData.emails.map((email, i) => i === index ? value : email);
    setJobData({ ...jobData, emails: updatedEmails });
  };

  const handleAddEmail = () => {
    setJobData({ ...jobData, emails: [...jobData.emails, ''] });
  };

  const handleRemoveEmail = (index) => {
    const filteredEmails = jobData.emails.filter((_, i) => i !== index);
    setJobData({ ...jobData, emails: filteredEmails });
  };

  const handleSubmit = async () => {
    if (!isFormValid || isLoading) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const trimmedData = {
      ...jobData,
      jobName: jobData.jobName.trim(),
      generalContractor: jobData.generalContractor.trim(),
      emails: jobData.emails.map(email => email.trim()),
      superintendentName: jobData.requireSuperintendentApproval ? 
        (jobData.superintendentName ? jobData.superintendentName.trim() : null) : null,
      superintendentPhoneNumber: jobData.requireSuperintendentApproval ? 
        (jobData.superintendentPhoneNumber ? formatPhoneNumberForE164(jobData.superintendentPhoneNumber) : null) : null,
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/job/create`, {
        ...trimmedData,
        customer_id: user.customer_id,
        requireSuperintendentApproval: jobData.requireSuperintendentApproval,
      });

      if (response.data.jobId) {
        navigate('/jobs');
      } else {
        setError('Failed to create job - no job ID returned');
      }
    } catch (error) {
      console.error('Error creating job:', error);
      setError(error.response?.data?.message || 'Error creating job. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mt-5 new-job-page">
      <div className="card shadow">
        <div className="card-body">
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          
          <div className="job-values-container">
            <div className="header-section">
              <h2 className="header-title">Create New Job</h2>
              <div className="button-group">
                <button 
                  className="btn btn-danger me-2" 
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button 
                  className="btn btn-primary" 
                  onClick={handleSubmit} 
                  disabled={!isFormValid || isLoading}
                >
                  {isLoading ? 'Creating...' : 'Create Job'}
                </button>
              </div>
            </div>
  
            <div className="info-section">
              <h6 className="section-title">Job Information</h6>
              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="jobName" className="job-values-label">Job Name</label>
                  <input
                    type="text"
                    className={`form-control ${validationErrors.jobName ? 'is-invalid' : ''}`}
                    id="jobName"
                    value={jobData.jobName}
                    onChange={(e) => setJobData({ ...jobData, jobName: e.target.value })}
                    disabled={isLoading}
                  />
                  {validationErrors.jobName && (
                    <div className="invalid-feedback">{validationErrors.jobName}</div>
                  )}
                </div>
  
                <div className="input-group">
                  <label htmlFor="generalContractor" className="job-values-label">General Contractor</label>
                  <input
                    type="text"
                    className="form-control"
                    id="generalContractor"
                    value={jobData.generalContractor}
                    onChange={(e) => setJobData({ ...jobData, generalContractor: e.target.value })}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
  
            <div className="info-section">
              <h6 className="section-title">Superintendent Information</h6>
              <div className="input-row">
                <div className="input-group">
                  <div className="d-flex align-items-center">
                    <label htmlFor="requireSuperintendentApproval" className="job-values-label mb-0">
                      Require Superintendent Approval
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input ms-2"
                      id="requireSuperintendentApproval"
                      checked={jobData.requireSuperintendentApproval}
                      onChange={(e) => {
                        setJobData({
                          ...jobData,
                          requireSuperintendentApproval: e.target.checked
                        });
                        setValidationErrors({});
                      }}
                      disabled={isLoading}
                    />
                    <span 
                      className="tooltip-icon ms-2"
                      data-bs-toggle="tooltip" 
                      data-bs-placement="top" 
                      title="When checked, both superintendent name and phone number must be filled out."
                    >?</span>
                  </div>
                </div>
              </div>
  
              <div className="input-row">
                <div className="input-group">
                  <label htmlFor="superintendentName" className="job-values-label">Superintendent Name</label>
                  <input
                    type="text"
                    className={`form-control ${validationErrors.superintendentName ? 'is-invalid' : ''}`}
                    id="superintendentName"
                    value={jobData.superintendentName}
                    onChange={(e) => setJobData({ ...jobData, superintendentName: e.target.value })}
                    disabled={!jobData.requireSuperintendentApproval || isLoading}
                  />
                  {validationErrors.superintendentName && (
                    <div className="invalid-feedback">{validationErrors.superintendentName}</div>
                  )}
                </div>
  
                <div className="input-group">
                  <label htmlFor="superintendentPhoneNumber" className="job-values-label">Superintendent Phone</label>
                  <input
                    type="tel"
                    className={`form-control ${validationErrors.superintendentPhoneNumber ? 'is-invalid' : ''}`}
                    id="superintendentPhoneNumber"
                    value={jobData.superintendentPhoneNumber}
                    onChange={(e) => {
                      const formattedNumber = formatPhoneNumberForDisplay(e.target.value);
                      setJobData({ ...jobData, superintendentPhoneNumber: formattedNumber });
                    }}
                    disabled={!jobData.requireSuperintendentApproval || isLoading}
                    placeholder="(123) 456-7890"
                  />
                  {validationErrors.superintendentPhoneNumber && (
                    <div className="invalid-feedback">{validationErrors.superintendentPhoneNumber}</div>
                  )}
                </div>
              </div>
            </div>
  
            <div className="info-section">
              <h6 className="section-title">COR Report Recipients</h6>
              {jobData.emails.map((email, index) => (
                <div key={index} className="email-input-group">
                  <div className="email-input-container">
                    <input
                      type="email"
                      className={`form-control ${!validateEmail(email) && email.length > 0 && email.includes('@') ? 'is-invalid' : ''}`}
                      value={email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                      disabled={isLoading}
                    />
                    {!validateEmail(email) && email.length > 0 && email.includes('@') && (
                      <div className="invalid-feedback">Invalid email address</div>
                    )}
                  </div>
                  <button 
                    type="button" 
                    className="btn btn-danger" 
                    onClick={() => handleRemoveEmail(index)}
                    disabled={isLoading}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <div className="btn-group">
                <button 
                  type="button" 
                  className="btn btn-secondary" 
                  onClick={handleAddEmail}
                  disabled={isLoading}
                >
                  Add Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewJobPage;