// src/modules/shared/EditButton.js
import React from 'react';

const EditButton = ({ isEditable, onEdit, onSave, onCancel, isDisabled, isLoading }) => {
  return (
    <div>
      {isEditable ? (
        <>
          <button 
            className="btn btn-primary me-2" 
            onClick={onSave} 
            disabled={isDisabled || isLoading}
          >
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Saving...
              </>
            ) : (
              'Save Changes'
            )}
          </button>
          <button 
            className="btn btn-danger" 
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </button>
        </>
      ) : (
        <button 
          className="btn btn-secondary" 
          onClick={onEdit}
          disabled={isDisabled}
        >
          Edit
        </button>
      )}
    </div>
  );
};

export default EditButton;