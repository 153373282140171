// PlanManager.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../constants/apiConfig';
import './PlanManager.css';
import LoadingSpinner from '../shared/LoadingSpinner';
const ChangePlanModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="change-modal-overlay">
      <div className="change-modal">
        <div className="change-modal-header">
          <h3 className="change-modal-title">Change Plan</h3>
          <button className="change-modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="change-modal-content">
          <p>To change your plan, please contact our support team. They will help you select the best plan for your needs.</p>
        </div>
        <div className="change-modal-actions">
          <button 
            className="change-modal-button change-modal-cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className="change-modal-button change-modal-confirm"
            onClick={() => navigate('/support')}
          >
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

const CancelPlanModal = ({ isOpen, onClose, onConfirm, currentPlan, customerId }) => {
  const [payAsYouGoBalance, setPayAsYouGoBalance] = useState(null);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {
      if (isOpen) {
        setIsLoadingBalance(true);
        try {
          const response = await fetch(`${API_BASE_URL}/api/subscription-info?customer_id=${customerId}`);
          if (response.ok) {
            const data = await response.json();
            const paygoInfo = data.find(sub => sub.planName === "Pay-as-you-go");
            if (paygoInfo) {
              setPayAsYouGoBalance(paygoInfo.usageCharges);
            }
          }
        } catch (error) {
          console.error('Error fetching balance:', error);
        } finally {
          setIsLoadingBalance(false);
        }
      }
    };

    fetchBalance();
  }, [isOpen, customerId]);

  if (!isOpen) return null;

  return (
    <div className="cancel-modal-overlay">
      <div className="cancel-modal">
        <div className="cancel-modal-header">
          <h3 className="cancel-modal-title">Cancel Plan</h3>
          <button className="cancel-modal-close" onClick={onClose}>&times;</button>
        </div>
        <div className="cancel-modal-content">
          <p className="cancel-modal-warning">
            Are you sure you want to cancel your plan? This action cannot be undone.
          </p>
          <div className="cancel-modal-info">
            <p><strong>Current Plan:</strong> {currentPlan.unlimited_plan ? "Unlimited" : currentPlan.planName}</p>
            <p><strong>Monthly Credits:</strong> {currentPlan.unlimited_plan ? "Unlimited" : (currentPlan.creditsPerMonth || 'N/A')}</p>
            <p>
              <strong>Price:</strong> ${Number(currentPlan.price).toFixed(2)}/
              {currentPlan.unlimited_plan ? "month" : (currentPlan.planName === "Pay-as-you-go" ? "credit" : "month")}
            </p>
          </div>
          <p>After cancellation:</p>
          <ul>
            <li>Your plan will be cancelled immediately</li>
            <li>You'll still have access to any remaining credits</li>
            {(payAsYouGoBalance || isLoadingBalance) && (
              <li>
                {isLoadingBalance ? (
                  "Calculating final balance..."
                ) : payAsYouGoBalance > 0 ? (
                  `A final charge of $${payAsYouGoBalance.toFixed(2)} for outstanding balance will be processed immediately`
                ) : null}
              </li>
            )}
            <li>No further charges will be made to your account</li>
          </ul>
        </div>
        <div className="cancel-modal-actions">
          <button 
            className="cancel-modal-button cancel-modal-cancel"
            onClick={onClose}
          >
            Keep Plan
          </button>
          <button 
            className="cancel-modal-button cancel-modal-confirm"
            onClick={onConfirm}
          >
            Confirm Cancellation
          </button>
        </div>
      </div>
    </div>
  );
};

const PlanManager = ({ customerId }) => {
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlanData = async () => {
      if (customerId) {
        setIsLoading(true);
        setError(null);
        setSuccessMessage(null);
        try {
          // Fetch plan data
          const currentPlanResponse = await fetch(`${API_BASE_URL}/api/current-plan?customer_id=${customerId}`);
          if (!currentPlanResponse.ok) {
            throw new Error('Failed to fetch plan data');
          }
          
          // Fetch credits info to check unlimited status
          const creditsResponse = await fetch(`${API_BASE_URL}/api/credits-info?customer_id=${customerId}`);
          if (!creditsResponse.ok) {
            throw new Error('Failed to fetch credits information');
          }

          const currentPlanData = await currentPlanResponse.json();
          const creditsData = await creditsResponse.json();

          // Combine the data
          const combinedPlanData = {
            ...currentPlanData,
            unlimited_plan: creditsData.isUnlimited
          };

          if (combinedPlanData.unlimited_plan) {
            combinedPlanData.planName = "Unlimited";
          }

          setCurrentPlan(combinedPlanData);
        } catch (error) {
          console.error('Error fetching plan data:', error);
          setError('Failed to load plan information. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPlanData();
  }, [customerId]);

  const handleChangePlan = () => {
    setShowChangeModal(true);
  };

  const handleCancelPlan = () => {
    setShowCancelModal(true);
  };

  const handleConfirmCancel = async () => {
    setError(null);
    setSuccessMessage(null);
    try {
      const response = await fetch(`${API_BASE_URL}/api/cancel-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: customerId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to cancel plan');
      }

      const result = await response.json();
      
      if (result.finalCharge) {
        setSuccessMessage(
          `Plan cancelled successfully. A final charge of $${result.finalCharge.toFixed(2)} has been processed for additional usage. ` +
          `You have ${result.remainingCredits} credits remaining.`
        );
      } else {
        setSuccessMessage(`Plan cancelled successfully. You have ${result.remainingCredits} credits remaining.`);
      }

      // Refetch plan data
      const currentPlanResponse = await fetch(`${API_BASE_URL}/api/current-plan?customer_id=${customerId}`);
      const creditsResponse = await fetch(`${API_BASE_URL}/api/credits-info?customer_id=${customerId}`);
      
      if (currentPlanResponse.ok && creditsResponse.ok) {
        const currentPlanData = await currentPlanResponse.json();
        const creditsData = await creditsResponse.json();
        
        const combinedPlanData = {
          ...currentPlanData,
          unlimited_plan: creditsData.isUnlimited
        };

        if (combinedPlanData.unlimited_plan) {
          combinedPlanData.planName = "Unlimited";
        }
        
        setCurrentPlan(combinedPlanData);
      }

    } catch (error) {
      console.error('Error canceling plan:', error);
      setError('Failed to cancel plan. Please try again later.');
    } finally {
      setShowCancelModal(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="plan-manager">
      {error && <div className="error-message">{error}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      
      {currentPlan ? (
        <div className="current-plan">
          <h4 className="section-title">Current Plan</h4>
          <div className="plan-details">
            <p className="plan-detail">
              <strong>Plan Name:</strong> {currentPlan.unlimited_plan ? "Unlimited" : (currentPlan.planName || "No active plan")}
            </p>
            <p className="plan-detail">
              <strong>Monthly Credits:</strong> {currentPlan.unlimited_plan ? "Unlimited" : (currentPlan.creditsPerMonth || 'N/A')}
            </p>
            <p className="plan-detail">
              <strong>Price: </strong> 
              {currentPlan.price != null
                ? `$${Number(currentPlan.price).toFixed(2)}/${currentPlan.unlimited_plan ? "month" : (currentPlan.planName === "Pay-as-you-go" ? "credit" : "month")}`
                : 'N/A'}
            </p>
          </div>
          <div className="plan-buttons">
            <button 
              onClick={handleChangePlan} 
              className="btn btn-primary plan-button"
            >
              Change Plan
            </button>
            {currentPlan.planName && !currentPlan.unlimited_plan && (
              <button 
                onClick={handleCancelPlan} 
                className="btn cancel-plan-btn plan-button"
              >
                Cancel Plan
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="no-plan">
          <p>You are not currently subscribed to any plan.</p>
          <button 
            onClick={() => navigate('/support')} 
            className="btn btn-primary plan-button"
          >
            Contact Support
          </button>
        </div>
      )}

      <CancelPlanModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleConfirmCancel}
        currentPlan={currentPlan}
        customerId={customerId}
      />

      <ChangePlanModal
        isOpen={showChangeModal}
        onClose={() => setShowChangeModal(false)}
      />
    </div>
  );
};

export default PlanManager;