import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../../constants/apiConfig';
import LoadingSpinner from '../shared/LoadingSpinner';
import './AllJobsPage.css';

const sortJobsAlphabetically = (jobs) => {
  return [...jobs].sort((a, b) => a.jobName.localeCompare(b.jobName));
};

const JobCard = ({ job, handleSelectJob, handleToggleJobArchive }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="job-card">
      <div className="job-info" onClick={() => setExpanded(!expanded)}>
        <div>{job.jobName}</div>
        <div>{job.status}</div>
      </div>
      {expanded && (
        <div className="job-details">
          <div className="job-details-row">
            <button 
              className={`btn btn-sm ${job.status !== 'Archived' ? 'btn-warning' : 'btn-success'}`}
              onClick={(e) => {
                e.stopPropagation();
                handleToggleJobArchive(job.id);
              }}
            >
              {job.status !== 'Archived' ? 'Archive' : 'Unarchive'}
            </button>
            <button 
              className="btn btn-sm btn-primary"
              onClick={(e) => {
                e.stopPropagation();
                handleSelectJob(job.jobName);
              }}
            >
              View Job
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const JobsPage = () => {
  const user = useSelector((state) => state.user.user);
  const [activeJobs, setActiveJobs] = useState([]);
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [modalInfo, setModalInfo] = useState({ show: false, message: '' });
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchJobs = useCallback(async () => {
    if (user) {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_BASE_URL}/jobs-page`, {
          params: {
            customer_id: user.customer_id,
          },
        });
        const { jobs } = response.data;
        const sortedJobs = sortJobsAlphabetically(jobs);
        setActiveJobs(sortedJobs.filter(job => job.status !== 'Archived'));
        setArchivedJobs(sortedJobs.filter(job => job.status === 'Archived'));
        return jobs;
      } catch (error) {
        console.error('Error fetching jobs:', error);
      } finally {
        setIsLoading(false);
      }
    }
    return null;
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchJobs();
    }
  }, [user, fetchJobs]);

  const handleSelectJob = (jobName) => {
    const formattedJobName = jobName.replace(/\s+/g, '-');
    navigate(`/job/${formattedJobName}`);
  };

  const handleCreateNewJob = () => {
    navigate('/job/new');
  };

  const handleToggleJobArchive = async (jobId) => {
    try {
      await axios.post(`${API_BASE_URL}/job/toggle-archive`, {
        jobId: jobId,
        customerId: user.customer_id,
      });
      fetchJobs();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const unexecutedCount = error.response.data.unexecutedCount;
        setModalInfo({
          show: true,
          message: `This job has ${unexecutedCount} unexecuted change order(s). Please execute or delete them before archiving.`,
        });
      } else {
        console.error('Error toggling job archive status:', error);
      }
    }
  };

  const JobTable = ({ jobs, isActive }) => (
    <table className="job-table">
      <thead>
        <tr>
          <th>Job Name</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <tr key={job.id} onClick={() => handleSelectJob(job.jobName)}>
            <td>{job.jobName}</td>
            <td>{job.status}</td>
            <td>
              <button 
                className={`btn btn-sm ${isActive ? 'btn-warning' : 'btn-success'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleJobArchive(job.id);
                }}
              >
                {isActive ? 'Archive' : 'Unarchive'}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const WarningModal = ({ show, message, onClose }) => (
    <>
      {show && (
        <div className="modal-backdrop show"></div>
      )}
      <div className={`modal ${show ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Warning</h5>
              <button type="button" className="close" onClick={onClose}>
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{message}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mt-5 jobs-page">
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="header-section">
            <h2 className="card-title mb-3">Active Jobs</h2>
            <button className="btn btn-primary create-job-btn" onClick={handleCreateNewJob}>
              Create New Job
            </button>
          </div>
          {isMobile ? (
            <div className="mobile-jobs">
              {activeJobs.map(job => (
                <JobCard
                  key={job.id}
                  job={job}
                  handleSelectJob={handleSelectJob}
                  handleToggleJobArchive={handleToggleJobArchive}
                />
              ))}
            </div>
          ) : (
            <JobTable jobs={activeJobs} isActive={true} />
          )}
          {activeJobs.length === 0 && <div>No active jobs to display.</div>}
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body">
          <h2 className="card-title mb-3">Archived Jobs</h2>
          {isMobile ? (
            <div className="mobile-jobs">
              {archivedJobs.map(job => (
                <JobCard
                  key={job.id}
                  job={job}
                  handleSelectJob={handleSelectJob}
                  handleToggleJobArchive={handleToggleJobArchive}
                />
              ))}
            </div>
          ) : (
            <JobTable jobs={archivedJobs} isActive={false} />
          )}
          {archivedJobs.length === 0 && <div>No archived jobs to display.</div>}
        </div>
      </div>

      <WarningModal 
        show={modalInfo.show} 
        message={modalInfo.message} 
        onClose={() => setModalInfo({ show: false, message: '' })}
      />
    </div>
  );
};

export default JobsPage;