import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';
import { debounce } from 'lodash';
import { convertToBackendFormat, convertFromBackendFormat } from '../utils';

export const fetchChangeOrderDetails = (customerId, corNumber, isNewOrder = false) => {
  return new Promise((resolve, reject) => {
    debouncedFetchChangeOrderDetails(customerId, corNumber, isNewOrder, resolve, reject);
  });
};

const debouncedFetchChangeOrderDetails = debounce(async (customerId, corNumber, isNewOrder, resolve, reject) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/change-order-details/fetch`, {
      params: {
        customer_id: customerId,
        cor_number: corNumber,
        is_new_order: isNewOrder,
      },
    });
    const { bigDict, jobOptions } = response.data;
    
    // Convert categories to frontend format when receiving
    if (bigDict && bigDict.categories) {
      bigDict.categories = convertFromBackendFormat(bigDict.categories);
    }
    
    resolve({ data: { changeOrderDetails: { bigDict, ...response.data }, jobOptions }, error: null });
  } catch (error) {
    console.error('Error fetching change order details:', error);
    reject({ data: null, error: error });
  }
}, 300);

export const updateChangeOrderDetails = async (customerId, phoneNumber, corNumber, updatedData, isNewOrder = false) => {
  try {
    // Create a deep copy of the data
    const dataCopy = JSON.parse(JSON.stringify(updatedData));
    
    // Convert categories to backend format
    if (dataCopy.categories) {
      dataCopy.categories = convertToBackendFormat(dataCopy.categories);
    }

    const response = await axios.put(`${API_BASE_URL}/change-order-details/update`, {
      customer_id: customerId,
      cor_number: corNumber,
      bigDict: dataCopy,
      is_new_order: isNewOrder,
      phone_number: phoneNumber,
    });
    
    // Convert the response data back to frontend format if needed
    if (response.data.changeOrderDetails?.bigDict?.categories) {
      response.data.changeOrderDetails.bigDict.categories = 
        convertFromBackendFormat(response.data.changeOrderDetails.bigDict.categories);
    }
    
    return response;
  } catch (error) {
    console.error('Error updating change order details:', error);
    throw error;
  }
};