/* Used by: modules/ChangeOrderComponents/ChangeOrderContainer.js, modules/ChangeOrderComponents/Totals/EditableTotals.js, modules/ChangeOrderComponents/ScheduleExtension/EditableScheduleExtension.js, modules/ChangeOrderComponents/JobInfo/EditableJobInfo.js, modules/ChangeOrderComponents/Categories/EditableCategories.js */

// src/modules/ChangeOrderComponents/redux/actions/changeOrderActions.js
import {
  fetchChangeOrderDetails,
  updateChangeOrderDetails,
} from '../services/ChangeOrderDetails';

export const FETCH_CHANGE_ORDER_REQUEST = 'FETCH_CHANGE_ORDER_REQUEST';
export const FETCH_CHANGE_ORDER_SUCCESS = 'FETCH_CHANGE_ORDER_SUCCESS';
export const FETCH_CHANGE_ORDER_FAILURE = 'FETCH_CHANGE_ORDER_FAILURE';
export const UPDATE_EDIT_VALUES = 'UPDATE_EDIT_VALUES';
export const SAVE_CHANGE_ORDER_REQUEST = 'SAVE_CHANGE_ORDER_REQUEST';
export const SAVE_CHANGE_ORDER_SUCCESS = 'SAVE_CHANGE_ORDER_SUCCESS';
export const SAVE_CHANGE_ORDER_FAILURE = 'SAVE_CHANGE_ORDER_FAILURE';
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE';
export const CANCEL_EDIT_MODE = 'CANCEL_EDIT_MODE';
export const EXIT_EDIT_MODE = 'EXIT_EDIT_MODE';


export const toggleEditMode = () => ({
  type: TOGGLE_EDIT_MODE,
});

export const exitEditMode = () => ({
  type: EXIT_EDIT_MODE,
});

export const fetchChangeOrderRequest = () => ({
  type: FETCH_CHANGE_ORDER_REQUEST,
});

export const fetchChangeOrderSuccess = (changeOrderDetails, jobOptions, isNewOrder) => ({
  type: FETCH_CHANGE_ORDER_SUCCESS,
  payload: { changeOrderDetails, jobOptions, isNewOrder },
});

export const fetchChangeOrderFailure = (error) => ({
  type: FETCH_CHANGE_ORDER_FAILURE,
  payload: error,
});

export const updateEditValues = (editValues) => ({
  type: UPDATE_EDIT_VALUES,
  payload: processEditValues(editValues),
});

export const saveChangeOrderRequest = () => ({
  type: SAVE_CHANGE_ORDER_REQUEST,
});

export const saveChangeOrderSuccess = (changeOrderDetails) => ({
  type: SAVE_CHANGE_ORDER_SUCCESS,
  payload: changeOrderDetails,
});

export const saveChangeOrderFailure = (error) => ({
  type: SAVE_CHANGE_ORDER_FAILURE,
  payload: error,
});

// Asynchronous action creator for fetching change order details
export const fetchChangeOrder = (user, corNumber, isNewOrder) => {
  return async (dispatch) => {
    dispatch(fetchChangeOrderRequest());
    try {
      const { data, error } = await fetchChangeOrderDetails(user.customer_id, corNumber, isNewOrder);
      if (error) {
        throw new Error(error);
      }
      const { changeOrderDetails, jobOptions } = data;
      dispatch(fetchChangeOrderSuccess(changeOrderDetails, jobOptions, isNewOrder));
    } catch (error) {
      dispatch(fetchChangeOrderFailure(error.message));
    }
  };
};

// Asynchronous action creator for saving change order
export const saveChangeOrder = (user, corNumber, editValues, isNewOrder) => {
  return async (dispatch) => {
    dispatch(saveChangeOrderRequest());

    try {
      const response = await updateChangeOrderDetails(user.customer_id, user.phone, corNumber, editValues, isNewOrder);
      if (response.data && response.data.changeOrderDetails) {
        dispatch(saveChangeOrderSuccess(response.data.changeOrderDetails));
        return Promise.resolve(response.data.changeOrderDetails);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      dispatch(saveChangeOrderFailure(error.message));
      return Promise.reject(error);
    }
  };
};

const processEditValues = (editValues) => {
  if (!editValues) {
    console.error("No edit values data to process.");
    return {};
  }

  const processedValues = JSON.parse(JSON.stringify(editValues));

  if (processedValues.categories) {
    Object.keys(processedValues.categories).forEach(category => {
      if (!processedValues.categories[category]) {
        processedValues.categories[category] = {};
        return;
      }

      Object.entries(processedValues.categories[category]).forEach(([itemId, item]) => {
        if (!item.id) {
          const newId = `temp-${category}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
          processedValues.categories[category][itemId] = {
            ...item,
            id: newId
          };
        }
      });
    });
  }

  return processedValues;
};