import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';
import { clearDeveloperLoggedIn, userLoggedIn, setDeveloperImpersonating } from '../../redux/actions/userActions';
import './DevDashboard.css';
const DevDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDeveloperLoggedIn = useSelector(state => state.user.isDeveloperLoggedIn);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [expandedCustomer, setExpandedCustomer] = useState(null);

  useEffect(() => {
    if (isDeveloperLoggedIn) {
      fetchCustomers();
    }
  }, [isDeveloperLoggedIn]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dev/customers`);
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setCustomers(response.data);
        setError('');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to fetch customers');
    }
  };

  const fetchEmployees = async (customerId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dev/customers/${customerId}/employees`);
      return response.data;
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to fetch employees');
      return [];
    }
  };

  const handleCustomerClick = async (customerId) => {
    if (expandedCustomer === customerId) {
      setExpandedCustomer(null);
    } else {
      const employees = await fetchEmployees(customerId);
      setCustomers(prevCustomers => 
        prevCustomers.map(customer => 
          customer.id === customerId ? { ...customer, employees } : customer
        )
      );
      setExpandedCustomer(customerId);
    }
  };

  const handleImpersonate = async (customerId, employeeId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/dev/impersonate`, {
        customer_id: customerId,
        employee_id: employeeId
      });
      if (response.data.success) {
        dispatch(setDeveloperImpersonating(true));
        dispatch(userLoggedIn({...response.data.employee, isImpersonated: true}));
        navigate('/dashboard');
      } else {
        setError(response.data.message || 'Failed to impersonate employee');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to impersonate employee');
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${API_BASE_URL}/logout`, { isDeveloper: true });
      setCustomers([]);
      dispatch(clearDeveloperLoggedIn());
      delete axios.defaults.headers.common['X-Developer-Phone'];
      navigate('/login');
    } catch (error) {
      setError('Failed to logout. Please try again.');
    }
  };

  return (
    <div className="dev-dashboard-root">
      <div className="container">
        <h1>Developer Dashboard</h1>
        {isDeveloperLoggedIn ? (
          <div>
            <div className="mb-3">
              <button className="btn btn-primary me-2" onClick={() => navigate('/analytics')}>Analytics</button>
              <button className="btn btn-secondary" onClick={handleLogout}>Logout</button>
            </div>
            <h3>Customers</h3>
            <ul className="list-group">
              {customers.map(customer => (
                <li key={customer.id} className="list-group-item">
                  <div className="d-flex justify-content-between align-items-center">
                    <span onClick={() => handleCustomerClick(customer.id)} style={{cursor: 'pointer'}}>
                      {customer.name}
                    </span>
                    <span className="text-muted">
                      ID: {customer.id}
                    </span>
                  </div>
                  {expandedCustomer === customer.id && customer.employees && (
                    <ul className="list-group mt-2">
                      {customer.employees.map(employee => (
                        <li key={employee.id} className="list-group-item d-flex justify-content-between align-items-center">
                          {employee.name} - {employee.role}
                          <button className="btn btn-sm btn-secondary" onClick={() => handleImpersonate(customer.id, employee.id)}>
                            Impersonate
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="alert alert-warning">
            You are not logged in as a developer. Please log in to access the Developer Dashboard.
          </div>
        )}
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>
    </div>
  );
};

export default DevDashboard;