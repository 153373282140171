import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import phoneImage from '../../assets/images/CreateChangeOrderGIF.mp4';
import companyLogo from '../../assets/images/TopSectionLogo.png';
import phoneimage2 from '../../assets/images/CreateChangeOrderGIF2.mp4';
import phoneimage3 from '../../assets/images/Phone-Section-Dashboard-Image.png';
import customer1Image from '../../assets/images/Zitting-Companies-Logo.png';
import customer2Image from '../../assets/images/ASM-Comany-Logo.png';
import customer3Image from '../../assets/images/Zitting-Window&Door-Logo.png';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { userLoggedIn, userLoggedOut } from '../../redux/actions/userActions';
import { authApi } from '../../constants/axiosConfig';
import MobileSidebar from '../shared/MobileSidebar/MobileSidebar';
import 'bootstrap/dist/css/bootstrap.min.css';

import './GlobalStyles.css';
import './TopSection.css';
import './BannerSection.css';
import './AboutSection.css';
import './PhoneSection.css';
import './Responsive.css';
import './Animations.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTopSection, setShowTopSection] = useState(false);
  const [isArrowAnimated, setIsArrowAnimated] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [setMessage] = useState('');

  const clearSessionAndLogout = useCallback(() => {
    Cookies.remove('sessionToken');
    Cookies.remove('phoneNumber');
    dispatch(userLoggedOut());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsArrowAnimated(true);
    }, 100);
  
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      document.documentElement.style.setProperty('--scroll-y', `${scrollY}px`);
      
      if (window.innerWidth > 768) {
        setShowTopSection(scrollY > 500);
      } else {
        setShowTopSection(false);
      }
    };
  
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScrollAnimation = (targetId) => {
    if (!isScrolling) {
      setIsScrolling(true);
      setTimeout(() => {
        document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => setIsScrolling(false), 1000);
      }, 100);
    }
  };

  const handleLearnMoreClick = () => {
    if (!isScrolling) handleScrollAnimation('phone-section');
  };
  
  const handleArrowClick = () => {
    if (!isScrolling) handleScrollAnimation('phone-section');
  };

  const handleLoginClick = async () => {
    const sessionToken = Cookies.get('sessionToken');
    const phoneNumber = Cookies.get('phoneNumber');
    
    if (!sessionToken || !phoneNumber) {
      navigate('/login');
      return;
    }
  
    try {
      const response = await authApi.post('/verify-session', {
        phone: phoneNumber,
        session_token: sessionToken
      });
  
      if (!response.data.success) {
        throw new Error('Session verification failed');
      }
  
      dispatch(userLoggedIn(response.data.user));
      navigate('/dashboard');
  
    } catch (error) {
      console.error('Session verification error:', error);
      clearSessionAndLogout();
      
      if (error.response) {
        switch (error.response.status) {
          case 401:
            setMessage('Your session has expired. Please log in again.');
            break;
          default:
            setMessage('An error occurred. Please try again later.');
        }
      } else {
        setMessage('Network error. Please check your connection and try again.');
      }
      
      navigate('/login');
    }
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleDemoClick = () => {
    navigate('/demo');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="landing-page-root">
      {isMobile && (
        <MobileSidebar 
          isOpen={isSidebarOpen} 
          toggleSidebar={toggleSidebar}
          companyLogo={companyLogo}
        />
      )}
      <div className="page-container">
        <div className={`top-section ${showTopSection ? 'visible' : ''}`}>
          <div className="left-content">
            <img src={companyLogo} alt="Company Logo" className="logo" />
            <span className="company-name">BridgePoint AI</span>
          </div>
          <div className="center-content">
            <button className="demo-button" onClick={handleDemoClick}>See How It Works</button>
          </div>
          <div className="right-content">
            <button className="btn btn-lg btn-primary me-2" onClick={handleLoginClick}>Log In</button>
            <button className="btn btn-lg btn-primary" onClick={handleSignUpClick}>Sign Up</button>
          </div>
        </div>
        <div className="landing-page">
          <section id="landing" className="banner-section">
            <div className="container text-center">
              <h1 className="display-3 fw-bold">
                Meet Change Order Assistant
                <span className="line-break"></span>
                <span className="text"></span>
              </h1>
              <p className="lead">Your tool for creating and tracking change order requests.</p>
              <div className="fade-line"></div>
              <div className="button-container">
                {!isMobile && (
                  <button className="btn btn-lg btn-primary" onClick={handleSignUpClick}>Sign Up</button>
                )}
                <button 
                  className={`btn btn-lg btn-outline-secondary`} 
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div 
              className={`scroll-arrow ${isArrowAnimated ? 'btn-bounce' : ''}`} 
              onClick={handleArrowClick}
            >
              <IoIosArrowDown />
            </div>
          </section>

          <section id="phone-section" className="phone-section">
            <div className="diagonal-line"></div>
            <div className="diagonal-line"></div>
            <div className="diagonal-line"></div>
            
            <div className="image-content top-left">
              <video 
                autoPlay 
                loop 
                muted 
                playsInline
                className="phone-image"
              >
                <source src={phoneImage} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            
            <div className="text-content">
              <h2>Use the assistant wherever you are.</h2>
            </div>
            
            <div className="image-content bottom-right">
              <video 
                autoPlay 
                loop 
                muted 
                playsInline
                className="phone-image"
              >
                <source src={phoneimage2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            
            <div className="bottom-center-container">
              <div className="text-content bottom-text">
                <p>Manage your change order requests with ease from the Change Order Dashboard.</p>
              </div>
              <div className="image-content bottom-center">
                <img src={phoneimage3} alt="Additional Change Order Assistant Feature" className="phone-image" />
              </div>
            </div>
            <div 
              className={`scroll-arrow blue ${isArrowAnimated ? 'btn-bounce' : ''}`} 
              onClick={() => handleScrollAnimation('about')}
            >
              <IoIosArrowDown />
            </div>
          </section>

          <section id="about" className="about-section">
            <div className="container">
              <h2>About Change Order Assistant</h2>
              <p>Change Order Assistant is a tool for subcontractors to create and track change order requests. It makes the process of managing change orders simple and effective.</p>
              
              <div className="trust-statement">
                <h3>Trusted by Companies Like You</h3>
                
                <div className="customer-reviews">
                  <div className="review">
                    <img src={customer1Image} alt="Customer 1 Logo" />
                  </div>
                  <div className="review">
                    <img src={customer2Image} alt="Customer 2 Logo" />
                  </div>
                  <div className="review">
                    <img src={customer3Image} alt="Customer 3 Logo" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;