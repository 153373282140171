import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import API_BASE_URL from '../../constants/apiConfig';
import './CreditsAndUsage.css';
import LoadingSpinner from '../shared/LoadingSpinner';
const CreditsAndUsage = () => {
  const [creditsInfo, setCreditsInfo] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const fetchBillingInfo = async () => {
      if (user && user.customer_id) {
        try {
          setIsLoading(true);
          const creditsResponse = await fetch(`${API_BASE_URL}/api/credits-info?customer_id=${user.customer_id}`);
          const subscriptionResponse = await fetch(`${API_BASE_URL}/api/subscription-info?customer_id=${user.customer_id}`);
          
          if (!creditsResponse.ok) {
            throw new Error('Failed to fetch credits information');
          }
          
          const creditsData = await creditsResponse.json();
          setCreditsInfo(creditsData);

          if (subscriptionResponse.ok) {
            const subscriptionData = await subscriptionResponse.json();
            setSubscriptionInfo(subscriptionData);
          } else {
            console.warn('Subscription information not available');
          }
        } catch (error) {
          console.error('Error fetching billing information:', error);
          setError(error.message || 'Failed to load billing information. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchBillingInfo();
  }, [user]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="credits-usage">
      <h3 className="card-title">Credits</h3>
      {creditsInfo && (
        <div className="credits-info">
          <p className="available-credits">
            Available Credits: {creditsInfo.isUnlimited ? 'Unlimited' : creditsInfo.availableCredits}
          </p>
        </div>
      )}
      {subscriptionInfo && subscriptionInfo.length > 0 ? (
        subscriptionInfo.map((subscription, index) => (
          <div key={index} className="subscription-info">
            <div className="subscription-grid">
              <div className="label-column">
                <p className="subscription-label">Subscription Renewal:</p>
                <p className="subscription-label">Subscription Amount:</p>
                <p className="subscription-label">Additional Charges:</p>
                <p className="subscription-label">Total Upcoming Bill:</p>
              </div>
              <div className="value-column">
                <p className="subscription-value">{new Date(subscription.nextRenewalDate * 1000).toLocaleDateString()}</p>
                <p className="subscription-value">${subscription.baseChargeAmount.toFixed(2)}</p>
                <p className="subscription-value">${subscription.usageCharges.toFixed(2)}</p>
                <p className="subscription-value">${subscription.totalAmountDue.toFixed(2)}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="no-subscription">Subscription information is currently unavailable.</p>
      )}
    </div>
  );
};

export default CreditsAndUsage;