import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CompanyPage.css';
import API_BASE_URL from '../../constants/apiConfig';
import EditButton from '../shared/EditButton';
import LoadingSpinner from '../shared/LoadingSpinner';

const RatesSection = ({ rates, isEditable, onRateChange, regionName }) => {
  const rateCategories = {
    labor: 'Labor Rates',
    material: 'Material Rates',
    general: 'General Rates'
  };

  const preventWheelChange = (e) => {
    e.target.blur();
  };

  // Group rates by category
  const groupedRates = Object.entries(rates || {}).reduce((acc, [key, rate]) => {
    if (!acc[rate.category]) {
      acc[rate.category] = [];
    }
    acc[rate.category].push({ ...rate, key });
    return acc;
  }, {});

  return (
    <div className="rates-section">
      <h5 className="mt-4">
        {regionName ? `Rates for ${regionName}` : 'Default Rates'}
      </h5>
      {Object.entries(groupedRates).map(([category, categoryRates]) => (
        <div key={category} className="rate-category mb-4">
          <h6>{rateCategories[category]}</h6>
          <div className="rate-grid">
            {categoryRates.map((rate) => (
              <div key={rate.key} className="form-group">
                <label htmlFor={rate.key} className="form-label">
                  {rate.name
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                  :
                  {rate.is_regional && (
                    <span className="badge badge-info ml-2">Regional</span>
                  )}
                </label>
                {isEditable ? (
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id={rate.key}
                      value={rate.value}
                      onWheel={preventWheelChange}
                      onChange={(e) => onRateChange(rate.key, parseFloat(e.target.value))}
                    />
                    {category === 'material' || category === 'general' ? (
                      <div className="input-group-append">
                        <span className="input-group-text">%</span>
                      </div>
                    ) : (
                      <div className="input-group-append">
                        <span className="input-group-text">$/hr</span>
                      </div>
                    )}
                  </div>
                ) : (
                  <p>
                    {category === 'material' || category === 'general' 
                      ? `${(rate.value).toFixed(2)}%`
                      : `$${rate.value.toFixed(2)}/hr`}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const CompanyPage = () => {
  const [logoUrl, setLogoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [editedDetails, setEditedDetails] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [logoToRemove, setLogoToRemove] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [showLogoModal, setShowLogoModal] = useState(false);

  const customerId = useSelector((state) => state.user.user?.customer_id);

  const fetchLogo = useCallback(async () => {
    if (!customerId) return;
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/logo/fetch?customer_id=${customerId}`, {
        method: 'GET',
      });
      if (response.ok) {
        const blob = await response.blob();
        setLogoUrl(URL.createObjectURL(blob));
      } else if (response.status === 404) {
        setLogoUrl(null);
      } else {
        throw new Error('Failed to fetch logo');
      }
    } catch (error) {
      setError('Error fetching logo: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  const fetchCompanyDetails = useCallback(async () => {
    if (!customerId) return;
    setIsLoading(true);
    setError(null);
    try {
      const url = `${API_BASE_URL}/company/details?customer_id=${customerId}${
        selectedRegionId ? `&region_id=${selectedRegionId}` : ''
      }`;
      const response = await fetch(url, {
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        
        // Convert decimal rates to percentages for display
        data.default_rates = Object.entries(data.default_rates).reduce((acc, [key, rate]) => {
          acc[key] = {
            ...rate,
            value: (rate.category === 'material' || rate.category === 'general') 
              ? rate.value * 100
              : rate.value
          };
          return acc;
        }, {});
        
        setCompanyDetails(data);
        setEditedDetails(data);
      } else {
        throw new Error('Failed to fetch company details');
      }
    } catch (error) {
      setError('Error fetching company details: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [customerId, selectedRegionId]);

  useEffect(() => {
    if (customerId) {
      fetchLogo();
      fetchCompanyDetails();
    }
  }, [customerId, fetchLogo, fetchCompanyDetails]);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      setLogoUrl(URL.createObjectURL(file));
      setLogoToRemove(false);
    }
  };

  const handleRemove = () => {
    setLogoToRemove(true);
    setLogoUrl(null);
    setLogoFile(null);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const detailsToSend = {
        ...editedDetails,
        default_rates: Object.entries(editedDetails.default_rates).reduce((acc, [key, rate]) => {
          acc[key] = {
            ...rate,
            value: (rate.category === 'material' || rate.category === 'general') 
              ? rate.value / 100
              : rate.value
          };
          return acc;
        }, {})
      };
   
      const url = `${API_BASE_URL}/company/update?customer_id=${customerId}${
        selectedRegionId ? `&region_id=${selectedRegionId}` : ''
      }`;
      
      const detailsResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(detailsToSend)
      });
      if (!detailsResponse.ok) throw new Error('Failed to update company details');
   
      if (logoFile) {
        const formData = new FormData();
        formData.append('logo', logoFile);
        const logoResponse = await fetch(`${API_BASE_URL}/logo/upload?customer_id=${customerId}`, {
          method: 'POST',
          body: formData,
        });
        if (!logoResponse.ok) throw new Error('Failed to upload logo');
      } else if (logoToRemove) {
        const removeResponse = await fetch(`${API_BASE_URL}/logo/remove?customer_id=${customerId}`, {
          method: 'DELETE',
        });
        if (!removeResponse.ok) throw new Error('Failed to remove logo');
      }
   
      setCompanyDetails(editedDetails);
      setIsEditable(false);
      setLogoFile(null);
      setLogoToRemove(false);
      await fetchCompanyDetails();
      await fetchLogo();
    } catch (error) {
      setError('Error updating company information: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditedDetails(companyDetails);
    setIsEditable(false);
    setLogoFile(null);
    setLogoToRemove(false);
    fetchLogo();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleRateChange = (rateKey, value) => {
    setEditedDetails(prev => ({
      ...prev,
      default_rates: {
        ...prev.default_rates,
        [rateKey]: {
          ...prev.default_rates[rateKey],
          value: value
        }
      }
    }));
  };

  const RegionSelector = () => (
    <div className="form-group mb-3">
      <label htmlFor="region-select" className="form-label">Region:</label>
      <select
        id="region-select"
        className="form-control"
        value={selectedRegionId || ''}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedRegionId(value ? parseInt(value) : null);
        }}
      >
        <option value="">Default (No Region)</option>
        {companyDetails?.regions?.map(region => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>
    </div>
  );

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  };

  const formatAddress = (details) => {
    return `${details.street_address}, ${details.city}, ${details.state} ${details.zip_code}`;
  };

  if (!customerId) {
    return <div className="alert alert-warning">User information not available.</div>;
  }

  return (
    <div className="coa-company-page-root">
      <div className="card shadow">
        <div className="card-header d-flex align-items-center">
          <h1 className="card-title mb-0">Company Settings</h1>
          <div className="edit-button-group">
            <EditButton 
              isEditable={isEditable}
              onEdit={handleEdit}
              onSave={handleSave}
              onCancel={handleCancel}
              isDisabled={isLoading}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="card-body">
          <RegionSelector />
          
          <div className="details-section">
            <h5 className="mt-4">Company Details</h5>
            {isLoading ? (
              <LoadingSpinner />
            ) : companyDetails ? (
              <div>
                {isEditable ? (
                  <div className="form-grid">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">Name:</label>
                      <input type="text" className="form-control" id="name" name="name" value={editedDetails.name} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="street_address" className="form-label">Address:</label>
                      <input type="text" className="form-control" id="street_address" name="street_address" value={editedDetails.street_address} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city" className="form-label">City:</label>
                      <input type="text" className="form-control" id="city" name="city" value={editedDetails.city} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="state" className="form-label">State:</label>
                      <input type="text" className="form-control" id="state" name="state" value={editedDetails.state} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="zip_code" className="form-label">Zip Code:</label>
                      <input type="text" className="form-control" id="zip_code" name="zip_code" value={editedDetails.zip_code} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="office_phone_number" className="form-label">Office Phone:</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="office_phone_number" 
                        name="office_phone_number" 
                        value={formatPhoneNumber(editedDetails.office_phone_number)} 
                        onChange={handleInputChange} 
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Company Logo:</label>
                      <div className="d-flex gap-2">
                        {logoUrl ? (
                          <button 
                            className="btn btn-danger"
                            onClick={handleRemove}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                Removing...
                              </>
                            ) : (
                              'Remove Logo'
                            )}
                          </button>
                        ) : (
                          <>
                            <button 
                              className="btn btn-primary"
                              onClick={() => document.getElementById('logo-upload').click()}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                  Processing...
                                </>
                              ) : (
                                'Upload Company Logo'
                              )}
                            </button>
                            <input
                              id="logo-upload"
                              type="file"
                              accept="image/*"
                              onChange={handleUpload}
                              style={{ display: 'none' }}
                              disabled={isLoading}
                            />
                          </>
                        )}
                        {logoUrl && (
                          <button 
                            className="btn btn-primary"
                            onClick={() => setShowLogoModal(true)}
                            disabled={isLoading}
                          >
                            View Company Logo
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="read-only-details">
                    <p><strong>Name:</strong> {companyDetails.name}</p>
                    <p><strong>Address:</strong> {formatAddress(companyDetails)}</p>
                    <p><strong>Office Phone:</strong> {formatPhoneNumber(companyDetails.office_phone_number)}</p>
                    {logoUrl ? (
                      <button 
                        className="btn btn-primary"
                        onClick={() => setShowLogoModal(true)}
                        disabled={isLoading}
                      >
                        View Company Logo
                      </button>
                    ) : (
                      <p><strong>Company Logo:</strong> No Company Logo Uploaded</p>
                    )}
                  </div>
                )}
                
                <RatesSection
                  rates={editedDetails.default_rates}
                  isEditable={isEditable}
                  onRateChange={handleRateChange}
                  regionName={companyDetails.regions?.find(r => r.id === selectedRegionId)?.name}
                />
              </div>
            ) : (
              <LoadingSpinner />
            )}
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
      {showLogoModal && (
        <div 
          className="modal show logo-modal" 
          role="dialog" 
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Company Logo</h5>
                <button 
                  type="button" 
                  className="close" 
                  onClick={() => setShowLogoModal(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {isLoading ? (
                  <LoadingSpinner />
                ) : logoUrl ? (
                  <img 
                    src={logoUrl} 
                    alt="Company Logo" 
                    className="company-logo-preview"
                  />
                ) : (
                  <p>No logo uploaded</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyPage;
