// src/modules/ChangeOrderComponents/JobInfo/EditableJobInfo.js
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateEditValues } from '../redux/changeOrderActions';
import './JobInfo.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditableJobInfo = ({ onValidationChange, showValidationMessage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editValues, jobOptions } = useSelector((state) => state.changeOrder);
  const [errors, setErrors] = useState({});

  const handleJobNameChange = (newJobName) => {
    setErrors(prev => ({ ...prev, job_name: '' }));
    if (newJobName === 'add-job') {
      navigate('/jobs');
    } else {
      dispatch(updateEditValues({ ...editValues, job_name: newJobName }));
    }
  };

  const gcName = jobOptions[editValues.job_name] || '';

  // Validation function
  const validate = useCallback(() => {
    const newErrors = {};
    if (!editValues.job_name) {
      newErrors.job_name = 'Please select a job';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [editValues.job_name]);

  // Update parent component about validation state
  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(validate);
    }
  }, [validate, onValidationChange]);

  // Sort job options alphabetically
  const sortedJobOptions = Object.keys(jobOptions).sort((a, b) => a.localeCompare(b));

  return (
    <div className="job-info-component mb-4">
      <h5 className="job-info-component__title mb-3">Job Information</h5>
      <div className="mb-3">
        <p className="job-info-component__flex-container">
          <strong className="job-info-component__label">Job Name:</strong>
          <select
            className={`job-info-component__select form-select ${showValidationMessage && errors.job_name ? 'is-invalid' : ''}`}
            value={editValues.job_name || ''}
            onChange={(e) => handleJobNameChange(e.target.value)}
          >
            <option value="">Select a job</option>
            {sortedJobOptions.map((jobName) => (
              <option key={jobName} value={jobName}>
                {jobName}
              </option>
            ))}
            <option value="add-job">Add New Job</option>
          </select>
        </p>
        {showValidationMessage && errors.job_name && (
          <p className="text-danger">{errors.job_name}</p>
        )}
      </div>
      <div className="mb-3">
        <p className="job-info-component__text">
          <strong>General Contractor:</strong> {gcName}
        </p>
      </div>
    </div>
  );
};

export default EditableJobInfo;