// src/modules/Analytics/Components/PageTracker.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import TimelineFilter, { calculateDateRange } from '../TimelineFilter';
import API_BASE_URL from '../../../constants/apiConfig';

const PageTracker = ({ isVisible }) => {
  const [pageViewData, setPageViewData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('1d');
  const [selectedEnvironment, setSelectedEnvironment] = useState('prod');
  const [selectedCompany, setSelectedCompany] = useState(null);

  const fetchPageViewData = useCallback(async (range) => {
    if (!isVisible) return;
    
    setIsLoading(true);
    try {
      const { startDate, endDate } = calculateDateRange(range, selectedEnvironment);
      const params = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
        environment: selectedEnvironment
      });
      
      const response = await axios.get(`${API_BASE_URL}/api/metrics/events/pageviews?${params.toString()}`, {
        withCredentials: true,
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      });

      if (response.data.success) {
        setPageViewData(response.data.data);
        setError(null);
      } else {
        throw new Error(response.data.message || 'Failed to fetch page view data');
      }
    } catch (err) {
      console.error('Error fetching page views:', err);
      setError('Unable to load page view data. Please try again later.');
      setPageViewData(null);
    } finally {
      setIsLoading(false);
    }
  }, [isVisible, selectedEnvironment]);

  useEffect(() => {
    if (isVisible) {
      fetchPageViewData(selectedRange);
      const interval = setInterval(() => fetchPageViewData(selectedRange), 60000);
      return () => clearInterval(interval);
    }
  }, [selectedRange, fetchPageViewData, isVisible]);

  if (!isVisible) {
    return null;
  }

  const renderCompanyViews = (company) => (
    <div key={company.id} className="mb-4">
      <div className="d-flex align-items-center mb-3">
        <button
          className={`btn btn-link text-decoration-none ${selectedCompany === company.id ? 'text-primary' : 'text-dark'}`}
          onClick={() => setSelectedCompany(selectedCompany === company.id ? null : company.id)}
        >
          <i className={`fas fa-chevron-${selectedCompany === company.id ? 'down' : 'right'} me-2`}></i>
          {company.name} ({company.view_count} views)
        </button>
      </div>
      
      {selectedCompany === company.id && (
        <div className="table-responsive ms-4">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>User</th>
                <th>Element</th>
              </tr>
            </thead>
            <tbody>
              {company.views.map((view, index) => (
                <tr key={index}>
                  <td>{new Date(view.timestamp).toLocaleString()}</td>
                  <td>
                    {view.first_name && view.last_name ? 
                      `${view.first_name} ${view.last_name}` : 
                      (view.phone_number || 'N/A')}
                  </td>
                  <td>{view.element_identifier || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );

  const renderContent = () => {
    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }
  
    if (isLoading) {
      return (
        <div className="text-center p-4">
          <Spinner animation="border" />
          <p>Loading page view data...</p>
        </div>
      );
    }
  
    if (!pageViewData || !pageViewData.companies || pageViewData.companies.length === 0) {
      return <div className="alert alert-info">No page view data available</div>;
    }
  
    return (
      <>  
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Total Page Views</h5>
                <h2>{pageViewData.total_views || 0}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Active Companies</h5>
                <h2>{pageViewData.unique_customers || 0}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Most Viewed Element</h5>
                <h2>{pageViewData.most_viewed_element || 'N/A'}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="company-views mt-4">
          <h4>Company Activity</h4>
          {pageViewData.companies.map(company => renderCompanyViews(company))}
        </div>
      </>
    );
  };

  return (
    <div className="page-views-dashboard">
      <div className="dashboard-card">
        <h3 className="card-title">Page View Analytics</h3>
        <div className="card-content">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <TimelineFilter
              selectedRange={selectedRange}
              onRangeChange={setSelectedRange}
              refreshButton={true}
              onRefresh={() => fetchPageViewData(selectedRange)}
              isLoading={isLoading}
            />
            <select
              className="form-select ms-3"
              style={{ width: 'auto' }}
              value={selectedEnvironment}
              onChange={(e) => setSelectedEnvironment(e.target.value)}
            >
              <option value="prod">Production</option>
              <option value="dev">Development</option>
              <option value="staging">Staging</option>
            </select>
          </div>
          {renderContent()}
        </div>
      </div>

      <style jsx>{`
        .company-views .btn-link:hover {
          background-color: #f8f9fa;
        }

        .company-views .table {
          background-color: #fff;
        }

        .company-views .table-responsive {
          border-left: 2px solid #dee2e6;
        }
      `}</style>
    </div>
  );
};

export default PageTracker;