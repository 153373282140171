// src/modules/Analytics/Analytics.js
import React, { useState } from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DevReturnButton from '../Dashboard/DevReturnButton';
import AnalyticsCounts from './Components/AnalyticsCounts';
import PageTracker from './Components/PageTracker';
import LoginTracker from './Components/LoginTracker';
import './Analytics.css';

const Analytics = () => {
 const navigate = useNavigate();
 const [activeTab, setActiveTab] = useState('counts');

 const handleBack = () => {
   navigate('/dev-dashboard');
 };

 const handleTabChange = (tab) => {
   setActiveTab(tab);
 };

 return (
   <div className="analytics-wrapper">
     <div className="container analytics-root">
       <div className="card shadow">
         <div className="card-body">
           <div className="d-flex justify-content-between align-items-center mb-4">
             <div className="d-flex align-items-center gap-3">
               <Button 
                 variant="outline-secondary" 
                 onClick={handleBack}
                 className="me-2"
                 data-testid="back-to-dashboard"
               >
                 ← Return to Developer Dashboard
               </Button>
               <h2>Analytics Dashboard</h2>
             </div>
             <div>
               <DevReturnButton />
             </div>
           </div>

           <Tabs
             activeKey={activeTab}
             onSelect={handleTabChange}
             className="mb-4"
           >
             <Tab eventKey="counts" title="Analytics Counts">
               <AnalyticsCounts />
             </Tab>
             <Tab eventKey="pageViews" title="Page Views">
               <PageTracker isVisible={activeTab === 'pageViews'} />
             </Tab>
             <Tab eventKey="logins" title="Login Activity">
               <LoginTracker isVisible={activeTab === 'logins'} />
             </Tab>
           </Tabs>
         </div>
       </div>
     </div>
   </div>
 );
};

export default Analytics;