// BasePaymentForm.js
import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './BasePaymentForm.css';

const BasePaymentForm = ({ onSubmit, onCancel, submitButtonText, cancelButtonText }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !email) {
      return;
    }

    setIsProcessing(true);
    setErrorMessage('');

    try {
      await onSubmit(stripe, elements, email);
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="email-input-container">
        <label htmlFor="email">Billing Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="email-input"
        />
      </div>
      <PaymentElement />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="button-container">
        <button 
          type="submit" 
          disabled={isProcessing || !stripe || !elements || !email}
          className="submit-button"
        >
          {isProcessing ? 'Processing...' : submitButtonText}
        </button>
        {onCancel && (
          <button 
            type="button" 
            onClick={onCancel} 
            disabled={isProcessing}
            className="cancel-button"
          >
            {cancelButtonText}
          </button>
        )}
      </div>
    </form>
  );
};

export default BasePaymentForm;