export const jobNameAndGC = (bigDict, jobOptions) => {
  if (!bigDict || !jobOptions) {
    return { jobName: '', gcName: '' };
  }

  const jobName = bigDict.job_name;
  const gcName = jobOptions[jobName];

  return { jobName, gcName };
};

export const convertToBackendFormat = (categories) => {
  const converted = {};
  
  Object.keys(categories).forEach(categoryName => {
    converted[categoryName] = {};
    
    Object.entries(categories[categoryName]).forEach(([_, itemDetails]) => {
      converted[categoryName][itemDetails.name] = {
        cost_per_unit: itemDetails.cost_per_unit,
        unit: itemDetails.unit,
        quantity: itemDetails.quantity
      };
    });
  });
  
  return converted;
};

export const convertFromBackendFormat = (categories) => {
  const converted = {};
  
  Object.keys(categories).forEach(categoryName => {
    converted[categoryName] = {};
    
    Object.entries(categories[categoryName]).forEach(([itemName, itemDetails]) => {
      const tempId = `temp-${categoryName}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      converted[categoryName][tempId] = {
        id: tempId,
        name: itemName,
        ...itemDetails
      };
    });
  });
  
  return converted;
};