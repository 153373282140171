import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditValues } from '../redux/changeOrderActions';
import './Categories.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditableCategories = ({ onValidationChange, showValidationMessage }) => {
  const dispatch = useDispatch();
  const { editValues } = useSelector((state) => state.changeOrder);
  const { categories } = editValues;
  const [validationErrors, setValidationErrors] = useState({});
  const [duplicateNameErrors, setDuplicateNameErrors] = useState({});

  // Add back the script loading and widget initialization
  useEffect(() => {
    const loadScript = async () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.1build.com/widget.js';
      script.async = true;
      document.body.appendChild(script);

      return new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
      });
    };

    const initWidget = async () => {
      try {
        await loadScript();

        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/onebuild-key`;

        const response = await fetch(apiUrl, {
          headers: {
            'Accept': 'application/json'
          }
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('API Response:', {
            status: response.status,
            statusText: response.statusText,
            body: errorText,
            url: apiUrl
          });
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.key) {
          throw new Error('No API key in response');
        }

        if (!window.onebuild) {
          throw new Error('Onebuild widget not available');
        }

        window.onebuild.init({ key: data.key });
      } catch (error) {
        console.error('Widget initialization error:', error);
      }
    };

    initWidget();
  }, []);

  const validateCostPerUnit = (value) => {
    if (value === '') return 'Cost per unit is required';
    if (isNaN(value)) return 'Cost must be a number';
    return '';
  };

  const validateQuantity = (value) => {
    if (value === '') return 'Quantity is required';
    if (!Number.isInteger(Number(value))) return 'Quantity must be a whole number';
    return '';
  };

  const checkForDuplicateNames = useCallback(() => {
    const duplicates = {};
    const allNames = new Map();

    Object.entries(categories).forEach(([categoryName, categoryItems]) => {
      Object.entries(categoryItems).forEach(([itemId, details]) => {
        const normalizedName = details.name.trim().toLowerCase();

        if (allNames.has(normalizedName)) {
          const existing = allNames.get(normalizedName);
          duplicates[`${existing.category}-${existing.itemId}`] = true;
          duplicates[`${categoryName}-${itemId}`] = true;
        } else {
          allNames.set(normalizedName, { category: categoryName, itemId });
        }
      });
    });

    setDuplicateNameErrors(duplicates);
    return Object.keys(duplicates).length > 0;
  }, [categories]);

  // Inform parent of potential validation errors
  useEffect(() => {
    const hasErrors = Object.entries(categories).some(([categoryName, categoryItems]) => {
      return Object.entries(categoryItems).some(([itemId, details]) => {
        const costError = validateCostPerUnit(details.cost_per_unit);
        const quantityError = validateQuantity(details.quantity);
        const hasDuplicateName = checkForDuplicateNames();
        return costError !== '' || quantityError !== '' || hasDuplicateName;
      });
    });

    if (onValidationChange) {
      onValidationChange(hasErrors);
    }
  }, [categories, onValidationChange, checkForDuplicateNames]);

  const CATEGORY_ORDER = ['labor', 'materials', 'equipment', 'other'];

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const handleMaterialLookup = () => {
    if (!window.onebuild) {
      console.error('Onebuild widget not available');
      return;
    }

    try {
      window.onebuild.open({
        onRateSelected: (args) => {
          const { source, selectedRate } = args;

          if (!source || !selectedRate) {
            console.error('Invalid material data received from widget');
            return;
          }

          const itemName = source.name || 'New Material';
          const newItemId = `temp-materials-${Date.now()}`;
          const costPerUnit = selectedRate.calculatedUnitRateUsdCents / 100;

          if (isNaN(costPerUnit)) {
            console.error('Invalid cost received from widget');
            return;
          }

          const updatedCategories = {
            ...categories,
            materials: {
              ...categories.materials,
              [newItemId]: {
                id: newItemId,
                name: itemName,
                cost_per_unit: costPerUnit,
                unit: selectedRate.uom || 'ea',
                quantity: 1,
              },
            },
          };

          dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
        },
        sourceTypeFilter: 'MATERIAL',
        closeOnRateSelected: true,
      });
    } catch (error) {
      console.error('Error opening widget:', error);
    }
  };

  const handleCategoryChange = (categoryName, itemId, field, value) => {
    let parsedValue = value;
    let validationMessage = '';

    if (field === 'cost_per_unit') {
      parsedValue = value === '' ? '' : parseFloat(value);
      validationMessage = validateCostPerUnit(value);
    } else if (field === 'quantity') {
      parsedValue = value === '' ? '' : parseInt(value, 10);
      validationMessage = validateQuantity(value);
    }

    setValidationErrors(prev => ({
      ...prev,
      [`${categoryName}-${itemId}-${field}`]: validationMessage
    }));

    const updatedCategories = {
      ...categories,
      [categoryName]: {
        ...categories[categoryName],
        [itemId]: {
          ...categories[categoryName][itemId],
          [field]: field === 'name' ? value : parsedValue,
        },
      },
    };

    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const handleAddItem = (categoryName) => {
    const newItemId = `temp-${categoryName}-${Date.now()}`;
    const updatedCategories = {
      ...categories,
      [categoryName]: {
        ...categories[categoryName],
        [newItemId]: {
          id: newItemId,
          name: `New Item ${Object.keys(categories[categoryName]).length + 1}`,
          cost_per_unit: 0,
          unit: 'ea',
          quantity: 0,
        },
      },
    };
    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const handleRemoveItem = (categoryName, itemId) => {
    const updatedCategories = {
      ...categories,
      [categoryName]: Object.entries(categories[categoryName])
        .filter(([key]) => key !== itemId)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
    };
    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const preventWheelChange = (e) => {
    e.target.blur();
  };

  const renderEditableCategorySection = (categoryData, categoryName) => {
    const subtotal = Object.entries(categoryData).reduce((total, [_, itemDetails]) => {
      return total + (itemDetails.quantity * parseFloat(itemDetails.cost_per_unit || 0));
    }, 0);

    return (
      <div className={`category-section mb-4 ${categoryName.toLowerCase()}`} key={categoryName}>
        <div className="category-header d-flex justify-content-between align-items-center mb-3">
          <h3 className="m-0">{capitalize(categoryName)}</h3>
          <div>
            <button 
              onClick={() => handleAddItem(categoryName.toLowerCase())} 
              className="btn btn-sm btn-primary me-2"
            >
              Add New Line
            </button>
            {categoryName.toLowerCase() === 'materials' && (
              <button 
                className="btn btn-sm btn-secondary"
                onClick={handleMaterialLookup}
              >
                Material Search
              </button>
            )}
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th>Item</th>
                <th>Cost / Unit</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(categoryData).map(([itemId, itemDetails]) => (
                <tr key={itemId}>
                  <td>
                    <div>
                      <input
                        type="text"
                        value={itemDetails.name}
                        onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemId, 'name', e.target.value)}
                        className={`form-control form-control-sm ${showValidationMessage && duplicateNameErrors[`${categoryName}-${itemId}`] ? 'is-invalid' : ''}`}
                      />
                      {showValidationMessage && duplicateNameErrors[`${categoryName}-${itemId}`] && (
                        <div className="invalid-feedback">
                          Duplicate item name. Each item must have a unique name.
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="input-group input-group-sm">
                      <input
                        type="number"
                        value={itemDetails.cost_per_unit}
                        onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemId, 'cost_per_unit', e.target.value)}
                        className={`form-control form-control-sm ${showValidationMessage && validationErrors[`${categoryName}-${itemId}-cost_per_unit`] ? 'is-invalid' : ''}`}
                        step="1"
                        onWheel={preventWheelChange}
                        required
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">/</span>
                      </div>
                      <input
                        type="text"
                        value={itemDetails.unit}
                        onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemId, 'unit', e.target.value)}
                        className="form-control form-control-sm"
                        style={{ width: '50px' }}
                      />
                    </div>
                    {showValidationMessage && validationErrors[`${categoryName}-${itemId}-cost_per_unit`] && (
                      <div className="invalid-feedback">
                        {validationErrors[`${categoryName}-${itemId}-cost_per_unit`]}
                      </div>
                    )}
                  </td>
                  <td>
                    <div>
                      <input
                        type="number"
                        value={itemDetails.quantity}
                        onChange={(e) => handleCategoryChange(categoryName.toLowerCase(), itemId, 'quantity', e.target.value)}
                        className={`form-control form-control-sm ${showValidationMessage && validationErrors[`${categoryName}-${itemId}-quantity`] ? 'is-invalid' : ''}`}
                        step="1"
                        onWheel={preventWheelChange}
                        required
                      />
                      {showValidationMessage && validationErrors[`${categoryName}-${itemId}-quantity`] && (
                        <div className="invalid-feedback">
                          {validationErrors[`${categoryName}-${itemId}-quantity`]}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>{formatCurrency(itemDetails.quantity * parseFloat(itemDetails.cost_per_unit || 0))}</td>
                  <td>
                    <button onClick={() => handleRemoveItem(categoryName.toLowerCase(), itemId)} className="btn btn-sm btn-danger">
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr className="subtotal-row">
                <td></td>
                <td></td>
                <td className="text-right"><strong>Subtotal</strong></td>
                <td><strong>{formatCurrency(subtotal)}</strong></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="editable-categories">
      {CATEGORY_ORDER.map((categoryName) => {
        const categoryData = categories[categoryName];
        if (categoryData) {
          return renderEditableCategorySection(categoryData, categoryName);
        }
        return null;
      })}
    </div>
  );
};

export default EditableCategories;
