// src/modules/Analytics/TimelineFilter.js
import React from 'react';
import PropTypes from 'prop-types';
import './TimelineFilter.css';

export const calculateDateRange = (range, environment) => {
  const endDate = new Date();
  const startDate = new Date();

  switch (range) {
    case '1h':
      startDate.setHours(endDate.getHours() - 1);
      break;
    case '1d':
      startDate.setDate(endDate.getDate() - 1);
      break;
    case '1w':
      startDate.setDate(endDate.getDate() - 7);
      break;
    case '1m':
      startDate.setMonth(endDate.getMonth() - 1);
      break;
    case '1y':
      startDate.setFullYear(endDate.getFullYear() - 1);
      break;
    default:
      startDate.setDate(endDate.getDate() - 1);
  }

  // Always return ISO strings with proper timezone handling
  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString()
  };
};

const TimelineFilter = ({ 
  selectedRange,
  selectedEnvironment,
  onRangeChange, 
  className = '',
  label = 'Time Range',
  refreshButton = false,
  onRefresh = null,
  isLoading = false
}) => {
  return (
    <div className={`timeline-filter ${className}`}>
      <div className="timeline-filter__group">
        <label className="timeline-filter__label">{label}</label>
        <select 
          className="timeline-filter__select form-select"
          value={selectedRange}
          onChange={(e) => onRangeChange(e.target.value)}
        >
          <option value="1h">Last Hour</option>
          <option value="1d">Last 24 Hours</option>
          <option value="1w">Last Week</option>
          <option value="1m">Last Month</option>
          <option value="1y">Last Year</option>
        </select>
      </div>

      {refreshButton && onRefresh && (
        <button 
          onClick={onRefresh}
          disabled={isLoading}
          className="timeline-filter__refresh-button btn btn-primary"
        >
          {isLoading ? (
            <>
              Refreshing...
            </>
          ) : (
            <>
              <i className="bi bi-arrow-clockwise"></i>
              Refresh
            </>
          )}
        </button>
      )}
    </div>
  );
};

TimelineFilter.propTypes = {
  selectedRange: PropTypes.oneOf(['1h', '1d', '1w', '1m', '1y']).isRequired,
  selectedEnvironment: PropTypes.string,
  onRangeChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  refreshButton: PropTypes.bool,
  onRefresh: PropTypes.func,
  isLoading: PropTypes.bool
};

export default TimelineFilter;