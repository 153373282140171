// ReadOnlyJobView.jsx
import React, { useState } from 'react';
import ChangeOrdersTable from '../shared/ChangeOrdersTable/ChangeOrdersTable';
import { formatPhoneNumberForDisplay } from '../shared/FormatPhoneNumber';
import EditButton from '../shared/EditButton';
import LoadingSpinner from '../shared/LoadingSpinner';
import './ReadOnlyJobView.css';

const JobValues = ({ jobData, onEdit, isEditable, onSave, onCancel, isFormValid }) => {
 const [isExpanded, setIsExpanded] = useState(false);

 return (
   <div className="job-values-container">
    <div className="collapsible-header">
      <div className="header-main-row">
        <div className="left-section">
          <div className="job-status">Status: {jobData.status}</div>
        </div>
        <div className="center-section">
          <div className="job-name">{jobData.jobName}</div>
        </div>
        <div className="right-section">
          <EditButton
            isEditable={isEditable}
            onEdit={onEdit}
            onSave={onSave}
            onCancel={onCancel}
            isDisabled={isEditable && !isFormValid}
          />
        </div>
      </div>
      <button 
        className="expand-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{isExpanded ? 'Collapse Details' : 'View Details'}</span>
        <span className={`collapse-arrow ${isExpanded ? 'expanded' : ''}`}>▼</span>
      </button>
    </div>

     <div className={`collapsible-content ${isExpanded ? 'expanded' : ''}`}>
       <div className="info-section">
         <h6 className="section-title">Job Information</h6>
         <div className="job-values-row">
           <div className="job-values-item">
             <span className="job-values-label">Job Name</span>
             <span className="job-values-content">{jobData.jobName}</span>
           </div>
           <div className="job-values-item">
             <span className="job-values-label">General Contractor</span>
             <span className="job-values-content">{jobData.generalContractor}</span>
           </div>
           <div className="job-values-item">
             <span className="job-values-label">Job Status</span>
             <span className="job-values-content">{jobData.status}</span>
           </div>
         </div>
       </div>

       {jobData.requireSuperintendentApproval && (
         <div className="info-section">
           <h6 className="section-title">Superintendent Information</h6>
           <div className="job-values-row">
             <div className="job-values-item">
               <span className="job-values-label">Require Superintendent Approval</span>
               <span className="job-values-content">Yes</span>
             </div>
             <div className="job-values-item">
               <span className="job-values-label">Superintendent Name</span>
               <span className="job-values-content">
                 {jobData.superintendentName || 'Not provided'}
               </span>
             </div>
             <div className="job-values-item">
               <span className="job-values-label">Superintendent Phone</span>
               <span className="job-values-content">
                 {jobData.superintendentPhoneNumber 
                   ? formatPhoneNumberForDisplay(jobData.superintendentPhoneNumber) 
                   : 'Not provided'}
               </span>
             </div>
           </div>
         </div>
       )}

       <div className="info-section">
         <h6 className="section-title">COR Report Recipients</h6>
         <table className="emails-table">
           <tbody>
             {jobData.emails && jobData.emails.length > 0 ? (
               jobData.emails.map((email, index) => (
                 <tr key={index}>
                   <td>{email}</td>
                 </tr>
               ))
             ) : (
               <tr>
                 <td>No current emails</td>
               </tr>
             )}
           </tbody>
         </table>
       </div>
     </div>
   </div>
 );
};

const ReadOnlyJobView = ({ 
 jobData, 
 isNewJob, 
 changeOrders, 
 errors,
 onEdit,
 onSave,
 onCancel,
 isEditable,
 isFormValid
}) => {
 return (
   <div className="readonly-job-view">
     {jobData && !isNewJob ? (
       <JobValues 
         jobData={jobData}
         onEdit={onEdit}
         onSave={onSave}
         onCancel={onCancel}
         isEditable={isEditable}
         isFormValid={isFormValid}
       />
     ) : (
       <div>Please fill in the job details.</div>
     )}

     {jobData && !isNewJob && (
       <>
         <h3 className="mt-4">Change Order Requests</h3>
         {errors.changeOrders && errors.changeOrders.response?.status !== 404 && (
           <div className="alert alert-warning" role="alert">
             Error fetching change orders: {errors.changeOrders.message}
           </div>
         )}
         
         {changeOrders.length > 0 ? (
           <div className="job-view-change-order-table">
             <ChangeOrdersTable
               changeOrders={changeOrders}
               excludeJobNames={true}
             />
           </div>
         ) : (
           <LoadingSpinner />
         )}
       </>
     )}
   </div>
 );
};

export default ReadOnlyJobView;